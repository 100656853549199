import * as firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({ 
	apiKey: "AIzaSyD2B-y6tp61bqP8OTZcLwRROl9FUT37Po4",
	authDomain: "fir-49511.firebaseapp.com",
	databaseURL: "https://fir-49511.firebaseio.com",
	projectId: "fir-49511",
	storageBucket: "fir-49511.appspot.com",
	messagingSenderId: "268879451436",
	appId: "1:268879451436:web:77c7771c873f309f0b8889",
	measurementId: "G-JKYHP6HHMQ"
});

const messaging = initializedFirebaseApp.messaging();

export { messaging };