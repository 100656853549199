import React from 'react';
import axios from 'axios';
import { messaging } from "./init-fcm";
import logo from './logo.svg';
import './App.css';

class App extends React.Component {
	
	constructor(props) {
		super(props);
		
		this.handlePrint = this.handlePrint.bind(this);
		this.handleButtonClick = this.handleButtonClick.bind(this);
	}
	
	async componentDidMount() {
		// 1. Register Service Worker
		if ("serviceWorker" in navigator) {
			navigator.serviceWorker
					 .register("./firebase-messaging-sw.js")
					 .then((registration) => console.log("Registration successful, scope is:", registration.scope))
					 .catch((err) => console.log("Service worker registration failed, error:", err));
		}
		
		
		// 2. Allow notification, and get token (browser identity)
		messaging.requestPermission()
				 .then(async function() {
					const token = await messaging.getToken();
					console.log(token);
				 })
				 .catch(function(err) {
					console.log("Unable to get permission to notify.", err);
				 });
		
		// 3. Attach Event Listenter to get push notification
		navigator.serviceWorker
				 .addEventListener(
					"message", 
					(message) => {
						this.handlePrint(message.data["firebase-messaging-msg-data"].data.title);
						//console.log("Get data title : ", message.data["firebase-messaging-msg-data"].data.title);
						//console.log("Get data body : ", message.data["firebase-messaging-msg-data"].data.body);
						console.log(message);
					}
				 );
		console.log(navigator.serviceWorker);
	}
	
	handlePrint(contex) {
		const xmlBody = '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">'+
							'<s:Body>'+
								'<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">'+
									'<text width="1" height="2"/>'+
									'<text lang="en" smooth="true">' + contex + '</text>'+
									'<feed unit="40"/>'+
									'<cut type="feed"/>'+
								'</epos-print>'+
							'</s:Body>'+
						'</s:Envelope>';
		
		axios
			.post("http://192.168.0.9/cgi-bin/epos/service.cgi?devid=local_printer&timeout=60000", xmlBody, { headers: { "Content-Type": "text/xml" } })
			.then(res => console.log("Handle Print Response", res))
			.catch(err => console.log("Handle Print Error", err));
	}
	
	handleButtonClick() {
		alert("Print");
		//this.handlePrint("....HOREEE....");
	}
	
	render() {
		return (
			<div className="App">
				<header className="App-header">
					<img src={logo} className="App-logo" alt="logo" />
					<button onClick={this.handleButtonClick}>Print !</button>
				</header>
			</div>
		);
	}
}

export default App;
